
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'BCN Report',
  components: {
    Form,
    Datatable,
    SearchBar,
    Swal,
    ElNotification,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      entityInfos: [] as any,
      tranches_info: [] as any,
       optionsTranche: [] as any,
      courseInfoData: [] as any,
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      optionsTPartner: [] as any,
      load: false,
      loading: false,
      tranche: [] as any,
      association: [] as any,
      btnCheck: false,
      entity_id: [] as any,
      tranche_id:[] as any,

      bcn_date: '' as any,

      showTrainningStatistics: false,
      componentKey: 0,
      actionActive: false,
      bcnReportInfo: [] as any,
      bcnsubtotalInfo: [] as any,

      TotalTrainee: 0 as any,
      Totalenrollment: 0 as any,
      TotalBcnRegister: 0 as any,
      TotalbcnrenrollPercentage: 0 as any,
      TotalnidenrollPercentage: 0 as any,
      TotalnidRegister: 0 as any,
    };
  },
   async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getAssociation();
    await this.getTranche();
  },
  methods: {
      async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('bcn_date', this.bcn_date);

      await ApiService.post('report/bcn_report_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      let formData = new FormData();
       formData.set('bcn_date', this.bcn_date);


      let data = `${this.VUE_APP_API_URL}/api/report/bcn_report_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      let formData = new FormData();
     formData.set('bcn_date', this.bcn_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/bcn_report_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Bcn Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
       async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
   async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async bcnReport() {
      this.load = true;
      this.showTrainningStatistics = true;
      await ApiService.get('report/bcn-report?bcn_date=' + this.bcn_date + 
      '&tranche_id=' +
        this.tranche_id +
        '&entity_id=' +
        this.entity_id
      )
        .then((response) => {
          if(response.data.data.totalsum.targetTrainee > 0){
             this.bcnReportInfo = response.data.data.bcnData;
            this.bcnsubtotalInfo = response.data.data.subtotal;
            this.TotalTrainee = response.data.data.totalsum.targetTrainee;
            this.Totalenrollment = response.data.data.totalsum.enrollment;
            this.TotalBcnRegister = response.data.data.totalsum.bcnregister;
            this.TotalbcnrenrollPercentage =
              response.data.data.totalsum.bcn_percentage.toFixed(2);
            this.TotalnidenrollPercentage =
              response.data.data.totalsum.nid_percentage.toFixed(2);
            this.TotalnidRegister = response.data.data.totalsum.nidregister;
          }
          
          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
